
// ロード時の処理
$(function () {

  //--------------------------------------------------
  // イベント設定
  //--------------------------------------------------

  $(SlideMenu.REF_MENU_BUTTON)
    .on(SlideMenu.EVENT_CLICK, SlideMenu.onClickMenuButton);

  $(SlideMenu.REF_OVERLAY)
    .on(SlideMenu.EVENT_CLICK, SlideMenu.onClickOverlay);
});

// クラス定義
(function(window) {

  var SlideMenu = function() {
  };

  //--------------------------------------------------
  // static 定数
  //--------------------------------------------------

  SlideMenu.REF_SLIDE_MENU  = '#slide_menu';
  SlideMenu.REF_MENU_BUTTON = '#menu_button';
  SlideMenu.REF_OVERLAY     = '#overlay';
  SlideMenu.REF_CONTAINER   = '#container';
  SlideMenu.CLASS_OPEN      = 'open';
  SlideMenu.EVENT_CLICK     = 'click';

  //--------------------------------------------------
  // static 変数
  //--------------------------------------------------

  SlideMenu.scrollTop = 0;

  //--------------------------------------------------
  // static メソッド : メイン
  //--------------------------------------------------

  //--------------------------------------------------
  // イベントハンドラ
  //--------------------------------------------------

  SlideMenu.onClickMenuButton = function() {
    var
      $slideMenu = $(SlideMenu.REF_SLIDE_MENU),
      $overlay = $(SlideMenu.REF_OVERLAY),
      $container = $(SlideMenu.REF_CONTAINER),
      slideMenuWidth = $slideMenu.outerWidth();

    var bodyPaddingTop = isNative ? 0 : 120;

    if (!$container.hasClass(SlideMenu.CLASS_OPEN)) {
      SlideMenu.scrollTop = $(window).scrollTop();
    }

    $container.toggleClass(SlideMenu.CLASS_OPEN);

    if ($container.hasClass(SlideMenu.CLASS_OPEN)) {
      $overlay.show();
      $container.css('left', slideMenuWidth);
      $container.css('top', bodyPaddingTop - SlideMenu.scrollTop);
      $slideMenu.css('left', 0);

      if (isNative) {
        $slideMenu.css('top', 0);
      }

    } else {
      $overlay.hide();
      $container.css('left', 0);
      $container.css('top', 0);
      setTimeout(function() { $(window).scrollTop(SlideMenu.scrollTop); }, 1);
      $slideMenu.css('left', -slideMenuWidth);
    }
  };

  SlideMenu.onClickOverlay = function() {
    var
      $slideMenu = $('#slide_menu'),
      $overlay = $('#overlay'),
      $container = $('#container'),
      slideMenuWidth = $slideMenu.outerWidth();

    $container.toggleClass(SlideMenu.CLASS_OPEN);
    $overlay.hide();
    $container.css('left', 0);
    $container.css('top', 0);
    setTimeout(function() { $(window).scrollTop(SlideMenu.scrollTop); }, 1);
    $slideMenu.css('left', -slideMenuWidth);
  };

  window.SlideMenu = SlideMenu;

}(window));

