//----------------------------------------------------
// Category
//----------------------------------------------------

// クラス定義
(function(window) {

  var Category = function() {
  };

  //--------------------------------------------------
  // static 定数
  //--------------------------------------------------

  // 「全体」のカテゴリID
  Category.ROOT_CATEGORY_ID = 1;

  // 属性 : カテゴリID
  Category.DATA_CATEGORY_ID = 'category-id';
  // 属性 : リンクか否か
  Category.DATA_IS_LINK = 'is-link';

  // 参照 : category_id の form 用
  Category.REF_SELECTED_ID = 'input[name="category_id"]';
  // 参照 : 選択中のカテゴリ名の表示用
  Category.REF_SELECTED_NAME = '#br_category_selected_name';

  // アイコン : 幹カテゴリ用(左)
  Category.ICON_CHILD = '<i class="fa fa-arrow-right color-cocoa"></i>';
  // アイコン : 幹カテゴリ用(右)
  Category.ICON_BRANCH = '<i class="fa fa-folder-open-o margin-left"></i>';

  //--------------------------------------------------
  // static 変数
  //--------------------------------------------------

  // カテゴリ一覧
  Category.dataList = categories;

  //--------------------------------------------------
  // static メソッド : データ管理
  //--------------------------------------------------

  // カテゴリを取得
  Category.get = function(categoryId) {
    if (Category.dataList[categoryId] == undefined) {
      return null;
    } else {
      return Category.dataList[categoryId];
    }
  };

  // 親カテゴリを取得
  Category.getParent = function(categoryId) {
    var category = Category.get(categoryId);

    if (category == null) {
      return null;
    }

    return Category.get(category.parent_category_id);
  };

  // 子カテゴリ一覧を取得
  Category.getChildren = function(categoryId) {
    var children = [];

    $.each(Category.dataList, function(index, category) {
      if (category.parent_category_id == categoryId) {
        children.push(category);
      }
    });

    children.sort(function(a, b) {
      if (a.blog_count > b.blog_count) return -1;
      if (a.blog_count < b.blog_count) return 1;
      return 0;
    });

    return children;
  };

  // 子カテゴリがあるか？
  Category.hasChild = function(categoryId) {
    var hasChild = false;

    $.each(Category.dataList, function(index, category) {
      if (category.parent_category_id == categoryId) {
        hasChild = true;
        return false;
      }
    });

    return hasChild;
  };

  // カテゴリ名を整形して返す
  Category.getName = function(categoryId, depth, ignoreRoot) {
    var name = '';

    do {
      var category = Category.get(categoryId);

      if (category == null || (ignoreRoot && categoryId == 1)) {
        break;
      }

      if (name.length == 0) {
        name = category.name;
      } else {
        name = category.name + " > " + name;
      }

      categoryId = category.parent_category_id;
      depth--;
    } while (depth > 0);

    return name;
  };

  //--------------------------------------------------
  // static メソッド : UI
  //--------------------------------------------------

  // 選択ボタンの jQuery オブジェクトを生成
  Category.generateListButton = function(categoryId, name, badgeNumber, isLink) {
    var $a = $('<a>')
      .addClass('list-group-item')
      .data(Category.DATA_CATEGORY_ID, categoryId)
      .data(Category.DATA_IS_LINK, isLink);

    $a.append($('<span>').addClass('name').html(name));

    if (badgeNumber != -1) {
      $a.append($('<span>').addClass('badge bg-cocoa').text(badgeNumber));
    }

    return $a;
  };

  // 選択されたカテゴリIDを、UIに反映
  Category.applySelectedCategoryId = function(categoryId) {
    var category = Category.get(categoryId);

    $(Category.REF_SELECTED_ID).val(category.category_id);
    $(Category.REF_SELECTED_NAME).text(category.name);
  };

  window.Category = Category;

}(window));

