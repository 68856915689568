//----------------------------------------------------
// Util
//----------------------------------------------------

// クラス定義
(function(window) {

  var Util = function() {
  };

  Util.isMobileSize = function() {
    return window.innerWidth < 992;
  };

  window.Util = Util;

}(window));

