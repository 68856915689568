
// ロード時の処理
$(function() {
  BlogRankInfo.setup();
});

(function(window) {

  var BlogRankInfo = function() {
  };

  //--------------------------------------------------
  // static 定数
  //--------------------------------------------------

  BlogRankInfo.REF         = '#blog_rank_info';
  BlogRankInfo.REF_CLOSE_BUTTON = '#blog_rank_info .close';
  BlogRankInfo.EVENT_CLICK = 'click';

  //--------------------------------------------------
  // static メソッド
  //--------------------------------------------------

  // イベント設定
  BlogRankInfo.setup = function() {
    $(BlogRankInfo.REF)
      .on(BlogRankInfo.EVENT_CLICK, BlogRankInfo.onClick);

    $(BlogRankInfo.REF_CLOSE_BUTTON)
      .on(BlogRankInfo.EVENT_CLICK, BlogRankInfo.onClickCloseButton);
  };

  //--------------------------------------------------
  // イベントハンドラ
  //--------------------------------------------------

  BlogRankInfo.onClick = function() {
    var url = $(BlogRankInfo.REF).data('url');
    window.location.href = url;
  };

  BlogRankInfo.onClickCloseButton = function(event) {
    var $root = $(BlogRankInfo.REF);
    $root.hide();
    event.stopPropagation();
  };

  window.BlogRankInfo = BlogRankInfo;

}(window));


