
// ロード時の処理
$(function () {

  // ダイアログが表示された際
  $(CategorySearch.REF_DIALOG)
    .on(CategorySearch.EVENT_SHOWN_BS_MODAL, CategorySearch.onShownBsModal);

  // 検索ワードのテキストエリアで、キーが押された際の処理
  $(CategorySearch.REF_SEARCH_WORD)
    .on(CategorySearch.EVENT_KEYDOWN, CategorySearch.onKeyDown);

  // 検索ボタンが押された際の処理
  $(CategorySearch.REF_SEARCH_BUTTON)
    .click(CategorySearch.onClickSearchButton);

  // カテゴリが選択された際の処理
  $(CategorySearch.REF_LIST)
    .on(CategorySearch.EVENT_CLICK, CategorySearch.ELEMENT_SELECT_BUTTON, CategorySearch.onClickCategoryButton);
});

// クラス定義
(function(window) {

  var CategorySearch = function() {
  };

  //--------------------------------------------------
  // static 定数
  //--------------------------------------------------

  CategorySearch.KEY_CODE_ENTER         = 13;
  CategorySearch.EVENT_CLICK            = 'click';
  CategorySearch.EVENT_KEYDOWN          = 'keydown';
  CategorySearch.EVENT_SHOWN_BS_MODAL   = 'shown.bs.modal';
  CategorySearch.REF_DIALOG             = '#br_category_search';
  CategorySearch.REF_LIST               = '#br_category_search .list-group';
  CategorySearch.REF_SEARCH_BUTTON      = '#br_category_search_btn';
  CategorySearch.REF_SEARCH_WORD        = '#br_category_search_word';
  CategorySearch.ELEMENT_SELECT_BUTTON  = 'a';
  CategorySearch.API_SEARCH             = '/api/category/search';

  //--------------------------------------------------
  // static 変数
  //--------------------------------------------------

  CategorySearch.lastSearchWord = '';
  CategorySearch.enable = true;

  //--------------------------------------------------
  // static メソッド : メイン
  //--------------------------------------------------

  // 検索
  CategorySearch.search = function(searchWord) {
    if (!CategorySearch.enable || searchWord.length <= 0 || searchWord == CategorySearch.lastSearchWord) {
      return;
    }

    CategorySearch.lastSearchWord = searchWord;
    CategorySearch.enable = false;

    $.get(CategorySearch.API_SEARCH, {
      search_word: searchWord
    }, function(data) {
      CategorySearch.enable = true;
      if (data.length == 0) {
        CategorySearch.clear();
        CategorySearch.appendText('検索にヒットしませんでした');
      } else {
        CategorySearch.clear();
        $.each(data, function(index, category) {
          var name = Category.getName(category.category_id, 2, true);
          CategorySearch.appendButton(category.category_id, name, category.blog_count);
        });
      }
    });
  };

  // カテゴリの決定
  CategorySearch.finish = function(categoryId) {
    CategorySearch.hide();
    window.location.href = contentBasePath + '/' + categoryId;
  };

  //--------------------------------------------------
  // static メソッド : UI : ダイアログ
  //--------------------------------------------------

  CategorySearch.hide = function() {
    $(CategorySearch.REF_DIALOG).modal('hide');
  };

  //--------------------------------------------------
  // static メソッド : UI : リスト
  //--------------------------------------------------

  // クリア
  CategorySearch.clear = function() {
    $(CategorySearch.REF_LIST).empty();
  };

  // リストに jQuery オブジェクトを追加
  CategorySearch.append = function($object) {
    $(CategorySearch.REF_LIST).append($object);
  };

  // リストにテキストを追加
  CategorySearch.appendText = function(text) {
    var $div = $('<div>')
      .addClass('list-group-item')
      .text(text);
    CategorySearch.append($div);
  };

  // リストにボタンを追加
  CategorySearch.appendButton = function(categoryId, name, badgeNumber, isLink) {
    $a = Category.generateListButton(categoryId, name, badgeNumber, isLink);
    CategorySearch.append($a);
  };

  //--------------------------------------------------
  // イベントハンドラ
  //--------------------------------------------------

  // ダイアログ表示時の処理
  CategorySearch.onShownBsModal = function() {
    // nothing to do.
  };

  // 検索ワード入力時の処理
  CategorySearch.onKeyDown = function(e) {
    if (e.which && e.which === CategorySearch.KEY_CODE_ENTER ||
        e.keyCode && e.keyCode === CategorySearch.KEY_CODE_ENTER
    ) {
      var searchWord = $(CategorySearch.REF_SEARCH_WORD).val();
      CategorySearch.search(searchWord);
      return false;
    } else {
      return true;
    }
  };

  // 検索ボタンが押された時の処理
  CategorySearch.onClickSearchButton = function() {
    var searchWord = $(CategorySearch.REF_SEARCH_WORD).val();
    CategorySearch.search(searchWord);
  };

  // カテゴリボタンが押された時
  CategorySearch.onClickCategoryButton = function() {
    var categoryId = $(this).data(Category.DATA_CATEGORY_ID);
    CategorySearch.finish(categoryId);
  };

  window.CategorySearch = CategorySearch;

}(window));

