//----------------------------------------------------
// CategoryLikeness
//----------------------------------------------------

// ロード時の処理
$(function () {

  //--------------------------------------------------
  // イベント設定
  //--------------------------------------------------

  // ボタンが押された際のイベント
  $(CategoryLikeness.REF_LIST)
    .on(CategoryLikeness.EVENT_CLICK, CategoryLikeness.ELEMENT_SELECT_BUTTON, CategoryLikeness.onClick);
});

// クラス定義
(function(window) {

  var CategoryLikeness = function() {
  };

  //--------------------------------------------------
  // static 定数
  //--------------------------------------------------

  CategoryLikeness.EVENT_CLICK            = 'click';
  CategoryLikeness.REF_ROOT               = '#br_category_likeness';
  CategoryLikeness.REF_LIST               = '#br_category_likeness .list-group';
  CategoryLikeness.ELEMENT_SELECT_BUTTON  = 'a';
  CategoryLikeness.API_SEARCH             = '/api/category/search';

  //--------------------------------------------------
  // static メソッド : メイン
  //--------------------------------------------------

  // 類似カテゴリを取得
  CategoryLikeness.fetch = function(categoryId) {
    $.get(CategoryLikeness.API_SEARCH, {
      category_id: categoryId
    }, function (data) {
      if (data.length == 0) {
        CategoryLikeness.hide();
      } else {
        CategoryLikeness.setup(data);
        CategoryLikeness.show();
      }
    });
  };

  // 類似カテゴリを決定
  CategoryLikeness.finish = function(categoryId) {
    Category.applySelectedCategoryId(categoryId);
    CategoryLikeness.hide();
  };

  // 類似カテゴリ一覧を構築
  CategoryLikeness.setup = function(dataList) {
    CategoryLikeness.clear();

    $.each(dataList, function(index, category) {
      if (Category.hasChild(category.category_id)) {
        return true;
      }
      CategoryLikeness.appendButton(category.category_id);
    });
  };

  //--------------------------------------------------
  // static メソッド : UI
  //--------------------------------------------------

  // 表示
  CategoryLikeness.show = function() {
    $(CategoryLikeness.REF_ROOT).removeClass('hidden');
  };

  // 非表示
  CategoryLikeness.hide = function() {
    $(CategoryLikeness.REF_ROOT).addClass('hidden');
  };

  //--------------------------------------------------
  // static メソッド : UI : リスト
  //--------------------------------------------------

  // クリア
  CategoryLikeness.clear = function() {
    $(CategoryLikeness.REF_LIST).empty();
  };

  // リストに jQuery オブジェクトを追加
  CategoryLikeness.append = function($object) {
    $(CategoryLikeness.REF_LIST).append($object);
  };

  // リストにボタンを追加
  CategoryLikeness.appendButton = function(categoryId) {
    var category = Category.get(categoryId);
    var name = Category.getName(categoryId, 99, true);
    var $a = Category.generateListButton(categoryId, name, category.blog_count, 0);
    CategoryLikeness.append($a);
  };

  //--------------------------------------------------
  // イベントハンドラ
  //--------------------------------------------------

  // ボタンが押された際の処理
  CategoryLikeness.onClick = function() {
    var categoryId = $(this).data(Category.DATA_CATEGORY_ID);
    CategoryLikeness.finish(categoryId);
  };

  window.CategoryLikeness = CategoryLikeness;

}(window));

