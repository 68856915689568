$(function () {

  // ajax の設定
  $.ajaxSetup({
    headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
  });

  // エラーでバックした際に、選択されたカテゴリ名を表示し直す
  if (typeof(categories) != 'undefined') {
    if ($('input[name="category_id"]').val() != 0) {
      var categoryId = $('input[name="category_id"]').val();

      if ($('#span_selected_category_name').size() > 0) {
        $('#span_selected_category_name').text(categories[categoryId].name);
      }
    }
  }

  // ブログの登録
  // ブログURLが設定された際の処理
  $('#text_blog_url').blur(function () {
    $.post('/api/feed', {
      url: $('#text_blog_url').val()
    }, function (data) {
      if (!data.success) {
        // 失敗
        console.log("feed not found.");
      } else {
        // 成功
        var nameObj = $('.blog-create input[name="name"]');
        if (nameObj.val().length > 0) {
          // 既に入力してあるので処理しない
        } else {
          nameObj.val(data.name);
        }

        var descriptionObj = $('.blog-create input[name="description"]');
        if (descriptionObj.val().length > 0) {
          // 既に入力してあるので処理しない
        } else {
          descriptionObj.val(data.description);
        }

        var rssUrlObj = $('.blog-create input[name="feed_url"]');
        if (rssUrlObj.val().length > 0) {
          // 既に入力してあるので処理しない
        } else {
          rssUrlObj.val(data.feed_url);
        }
      }
    });
  });
});

