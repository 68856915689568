//--------------------------------------------------
// CategoryList
//--------------------------------------------------

// ロード時の処理
$(function () {

  // ダイアログが表示された際
  $(CategoryList.REF_DIALOG)
    .on(CategoryList.EVENT_SHOWN_BS_MODAL, CategoryList.onShownBsModal);

  // カテゴリが選択された際の処理
  $(CategoryList.REF_LIST)
    .on(CategoryList.EVENT_CLICK, CategoryList.ELEMENT_SELECT_BUTTON, CategoryList.onClick);

});

// クラス定義
(function(window) {

  var CategoryList = function() {
  };

  //--------------------------------------------------
  // static 定数
  //--------------------------------------------------

  CategoryList.EVENT_CLICK            = 'click';
  CategoryList.EVENT_SHOWN_BS_MODAL   = 'shown.bs.modal';
  CategoryList.REF_DIALOG             = '#br_category_list';
  CategoryList.REF_LIST               = '#br_category_list .list-group';
  CategoryList.ELEMENT_SELECT_BUTTON  = 'a';

  //--------------------------------------------------
  // static メソッド : メイン
  //--------------------------------------------------

  // 構築
  CategoryList.setup = function(categoryId) {
    var category = Category.get(categoryId);

    CategoryList.clear();
    CategoryList.appendButtonParent(categoryId);
    CategoryList.appendButtonCurrent(categoryId, category.name, category.blog_count);

    var children = Category.getChildren(categoryId);

    $.each(children, function(index, category) {
      // 登録ブログが無いカテゴリは表示しない
      if (category.blog_count == 0) {
        return true;
      }

      var name = Category.getName(category.category_id, 1, false);

      if (category.sub_category_count > 0) {
        CategoryList.appendButtonBranch(category.category_id, name, category.blog_count);
      } else {
        CategoryList.appendButtonLeaf(category.category_id, name, category.blog_count);
      }
    });
  };

  // 決定
  CategoryList.finish = function(categoryId) {
    CategoryList.hide();
    window.location.href = contentBasePath + '/' + categoryId;
  };

  //--------------------------------------------------
  // static メソッド : UI : ダイアログ
  //--------------------------------------------------

  CategoryList.hide = function() {
    $(CategoryList.REF_DIALOG).modal('hide');
  };

  //--------------------------------------------------
  // static メソッド : UI : リスト
  //--------------------------------------------------

  // クリア
  CategoryList.clear = function() {
    $(CategoryList.REF_LIST).empty();
  };

  // リストに jQuery オブジェクトを追加
  CategoryList.append = function($object) {
    $(CategoryList.REF_LIST).append($object);
  };

  //--------------------------------------------------
  // static メソッド : UI : ボタン
  //--------------------------------------------------

  // リストにボタンを追加
  CategoryList.appendButton = function(categoryId, name, badgeNumber, isLink) {
    var $a = $('<a>')
      .addClass('list-group-item')
      .data(Category.DATA_CATEGORY_ID, categoryId)
      .data(Category.DATA_IS_LINK, isLink)
      .html(name);

    if (badgeNumber != -1) {
      $a.append($('<span>').addClass('badge bg-cocoa').text(badgeNumber));
    }

    $(CategoryList.REF_LIST).append($a);
  };

  // 現在のカテゴリ選択ボタンを追加
  CategoryList.appendButtonCurrent = function(categoryId, name, badgeNumber) {
    name = '<span>' + name + '</span>';
    var $a = Category.generateListButton(categoryId, name, badgeNumber, 1);
    CategoryList.append($a);
  };

  // 中間カテゴリの選択ボタンを追加
  CategoryList.appendButtonBranch = function(categoryId, name, badgeNumber) {
    name = Category.ICON_CHILD + '<span class="margin-left">' + name + '</span>' + Category.ICON_BRANCH;
    var $a = Category.generateListButton(categoryId, name, badgeNumber, 0);
    CategoryList.append($a);
  };

  // 末端カテゴリの選択ボタンを追加
  CategoryList.appendButtonLeaf = function(categoryId, name, badgeNumber) {
    name = Category.ICON_CHILD + '<span class="margin-left">' + name + '</span>';
    var $a = Category.generateListButton(categoryId, name, badgeNumber, 1);
    CategoryList.append($a);
  };

  // 親カテゴリへ戻るボタンを追加
  CategoryList.appendButtonParent = function(categoryId) {
    var parentCategory = Category.getParent(categoryId);

    if (parentCategory == null) {
      return;
    }

    var name = '<span>親カテゴリへ</span>';
    var $a = Category.generateListButton(parentCategory.category_id, name, -1, 0);
    CategoryList.append($a);
  };

  //--------------------------------------------------
  // イベントハンドラ
  //--------------------------------------------------

  // ダイアログが表示された際の処理
  CategoryList.onShownBsModal = function() {
    CategoryList.setup(Category.ROOT_CATEGORY_ID);
  };

  // カテゴリボタンが押された時の処理
  CategoryList.onClick = function() {
    var categoryId = $(this).data(Category.DATA_CATEGORY_ID);
    var isLink = $(this).data(Category.DATA_IS_LINK) == 1;

    if (isLink) {
      CategoryList.finish(categoryId);
    } else {
      CategoryList.setup(categoryId);
    }
  };

  window.CategoryList = CategoryList;

}(window));

