
// ロード時の処理
$(function () {

  //--------------------------------------------------
  // イベント設定
  //--------------------------------------------------

  // ダイアログが表示された際
  $(CategorySelect.REF_DIALOG)
    .on(CategorySelect.EVENT_SHOWN_BS_MODAL, CategorySelect.onShownBsModal);

  // 検索ワードのテキストエリアで、キーが押された際の処理
  $(CategorySelect.REF_SEARCH_WORD)
    .on(CategorySelect.EVENT_KEYDOWN, CategorySelect.onKeyDown);

  // 検索ボタンが押された際の処理
  $(CategorySelect.REF_SEARCH_BUTTON)
    .click(CategorySelect.onClickSearchButton);

  // カテゴリが選択された際の処理
  $(CategorySelect.REF_LIST)
    .on(CategorySelect.EVENT_CLICK, CategorySelect.ELEMENT_SELECT_BUTTON, CategorySelect.onClickCategoryButton);
});

// クラス定義
(function(window) {

  var CategorySelect = function() {
  };

  //--------------------------------------------------
  // static 定数
  //--------------------------------------------------

  CategorySelect.KEY_CODE_ENTER         = 13;
  CategorySelect.EVENT_CLICK            = 'click';
  CategorySelect.EVENT_KEYDOWN          = 'keydown';
  CategorySelect.EVENT_SHOWN_BS_MODAL   = 'shown.bs.modal';
  CategorySelect.REF_DIALOG             = '#br_category_select';
  CategorySelect.REF_LIST               = '#br_category_select .list-group';
  CategorySelect.REF_SEARCH_BUTTON      = '#br_category_select_btn';
  CategorySelect.REF_SEARCH_WORD        = '#br_category_select_word';
  CategorySelect.ELEMENT_SELECT_BUTTON  = 'a';
  CategorySelect.API_SEARCH             = '/api/category/search';

  //--------------------------------------------------
  // static 変数
  //--------------------------------------------------

  CategorySelect.lastSearchWord = '';
  CategorySelect.searchEnable = true;

  //--------------------------------------------------
  // static メソッド : メイン
  //--------------------------------------------------

  // ツリー
  CategorySelect.setup = function(categoryId) {
    var category = Category.get(categoryId);

    CategorySelect.clear();
    CategorySelect.appendButtonParent(categoryId);

    var children = Category.getChildren(categoryId);

    $.each(children, function(index, category) {
      if (category.sub_category_count > 0) {
        CategorySelect.appendButtonBranch(category.category_id, category.name, category.blog_count);
      } else {
        CategorySelect.appendButtonLeaf(category.category_id, category.name, category.blog_count);
      }
    });
  };

  // 検索
  CategorySelect.search = function(searchWord) {
    if (!CategorySelect.searchEnable || searchWord.length <= 0 || searchWord == CategorySelect.lastSearchWord) {
      return;
    }

    CategorySelect.lastSearchWord = searchWord;
    CategorySelect.searchEnable = false;

    $.get(CategorySelect.API_SEARCH, {
      search_word: searchWord
    }, function(data) {
      CategorySelect.searchEnable = true;
      if (data.length == 0) {
        CategorySelect.clear();
        CategorySelect.appendButtonBack();
        CategorySelect.appendListText('検索にヒットしませんでした');
      } else {
        CategorySelect.clear();
        CategorySelect.appendButtonBack();

        $.each(data, function(index, category) {
          var name = Category.getName(category.category_id, 2, false);
          if (category.sub_category_count > 0) {
            CategorySelect.appendButtonBranch(category.category_id, name, category.blog_count);
          } else {
            CategorySelect.appendButtonLeaf(category.category_id, name, category.blog_count);
          }
        });
      }
    });
  };

  // カテゴリの決定
  CategorySelect.finish = function(categoryId) {
    CategorySelect.hide();
    Category.applySelectedCategoryId(categoryId);
    CategoryLikeness.fetch(categoryId);
  };

  //--------------------------------------------------
  // static メソッド : UI : ダイアログ
  //--------------------------------------------------

  // 非表示
  CategorySelect.hide = function() {
    $(CategorySelect.REF_DIALOG).modal('hide');
  };

  //--------------------------------------------------
  // static メソッド : UI : リスト
  //--------------------------------------------------

  // クリア
  CategorySelect.clear = function() {
    $(CategorySelect.REF_LIST).empty();
  };

  // リストに jQuery オブジェクトを追加
  CategorySelect.append = function($object) {
    $(CategorySelect.REF_LIST).append($object);
  };

  // リストにテキストを追加
  CategorySelect.appendListText = function(text) {
    var $div = $('<div>')
      .addClass('list-group-item')
      .text(text);
    CategorySelect.append($div);
  };

  //--------------------------------------------------
  // static メソッド : UI : ボタン
  //--------------------------------------------------

  // 選択ボタンの追加 : 根
  CategorySelect.appendButtonBack = function() {
    var name = '<span>カテゴリ一覧に戻る</span>';
    $a = Category.generateListButton(Category.ROOT_CATEGORY_ID, name, -1, 0);
    CategorySelect.append($a);
  };

  // 選択ボタンの追加 : 親
  CategorySelect.appendButtonParent = function(categoryId) {
    var parentCategory = Category.getParent(categoryId);
    if (parentCategory == null) {
      return;
    }
    var name = '<span>親カテゴリへ</span>';
    $a = Category.generateListButton(parentCategory.category_id, name, -1, 0);
    CategorySelect.append($a);
  };

  // 選択ボタンの追加 : 幹
  CategorySelect.appendButtonBranch = function(categoryId, name, badgeNumber) {
    name = Category.ICON_CHILD + '<span class="margin-left">' + name + '</span>' + Category.ICON_BRANCH;
    $a = Category.generateListButton(categoryId, name, badgeNumber, 0);
    CategorySelect.append($a);
  };

  // 選択ボタンの追加 : 葉
  CategorySelect.appendButtonLeaf = function(categoryId, name, badgeNumber) {
    name = Category.ICON_CHILD + '<span class="margin-left">' + name + '</span>';
    $a = Category.generateListButton(categoryId, name, badgeNumber, 1);
    CategorySelect.append($a);
  };

  //--------------------------------------------------
  // イベントハンドラ
  //--------------------------------------------------

  // ダイアログ表示時の処理
  CategorySelect.onShownBsModal = function() {
    CategorySelect.setup(Category.ROOT_CATEGORY_ID);
  };

  // 検索ワード入力時の処理
  CategorySelect.onKeyDown = function(e) {
    if (e.which && e.which === CategorySelect.KEY_CODE_ENTER ||
        e.keyCode && e.keyCode === CategorySelect.KEY_CODE_ENTER
    ) {
      var searchWord = $(CategorySelect.REF_SEARCH_WORD).val();
      CategorySelect.search(searchWord);
      return false;
    } else {
      return true;
    }
  };

  // 検索ボタンが押された時の処理
  CategorySelect.onClickSearchButton = function() {
    var searchWord = $(CategorySelect.REF_SEARCH_WORD).val();
    CategorySelect.search(searchWord);
  };

  // カテゴリボタンが押された時の処理
  CategorySelect.onClickCategoryButton = function() {
    var categoryId = $(this).data(Category.DATA_CATEGORY_ID);
    var isLink = $(this).data(Category.DATA_IS_LINK) == 1;

    if (isLink) {
      CategorySelect.finish(categoryId);
    } else {
      CategorySelect.setup(categoryId);
    }
  };

  window.CategorySelect = CategorySelect;

}(window));

