//----------------------------------------------------
// pages/top
//----------------------------------------------------

// ロード時の処理
$(function() {

  var timer = false;

  if (!$.fn['slick']) {
    return;
  }

  $('#slick_1').slick({
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    infinite: true,
    draggable: true,
    accessibility: true,
    autoplay: true,
    autoplaySpeed: 3000
  });

  $('#slick_2').slick({
    dots: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    infinite: true,
    draggable: true,
    accessibility: true,
    autoplay: true,
    autoplaySpeed: 3000
  });

  $(window).resize(function() {
    if (timer !== false) {
      clearTimeout(timer);
    }
    timer = setTimeout(function() {
      if (Util.isMobileSize()) {
        //console.log("mobile");
      } else {
        //console.log("pc");
      }
    }, 200);
  });

  $('.ranking').removeClass('hidden');
});

