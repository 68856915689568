
// ロード時の処理
$(function () {

  //--------------------------------------------------
  // イベント設定
  //--------------------------------------------------

  // ダイアログが表示された際
  $(CategoryChange.REF_DIALOG)
    .on(CategoryChange.EVENT_SHOWN_BS_MODAL, CategoryChange.onShownBsModal);

  // 検索ワードのテキストエリアで、キーが押された際の処理
  $(CategoryChange.REF_SEARCH_WORD)
    .on(CategoryChange.EVENT_KEYDOWN, CategoryChange.onKeyDown);

  // 検索ボタンが押された際の処理
  $(CategoryChange.REF_SEARCH_BUTTON)
    .click(CategoryChange.onClickSearchButton);

  // カテゴリが選択された際の処理
  $(CategoryChange.REF_LIST)
    .on(CategoryChange.EVENT_CLICK, CategoryChange.ELEMENT_SELECT_BUTTON, CategoryChange.onClickCategoryButton);
});

// クラス定義
(function(window) {

  var CategoryChange = function() {
  };

  //--------------------------------------------------
  // static 定数
  //--------------------------------------------------

  CategoryChange.KEY_CODE_ENTER         = 13;
  CategoryChange.EVENT_CLICK            = 'click';
  CategoryChange.EVENT_KEYDOWN          = 'keydown';
  CategoryChange.EVENT_SHOWN_BS_MODAL   = 'shown.bs.modal';
  CategoryChange.REF_DIALOG             = '#br_category_change';
  CategoryChange.REF_LIST               = '#br_category_change .list-group';
  CategoryChange.REF_SEARCH_BUTTON      = '#br_category_change_btn';
  CategoryChange.REF_SEARCH_WORD        = '#br_category_change_word';
  CategoryChange.ELEMENT_SELECT_BUTTON  = 'a';
  CategoryChange.API_SEARCH             = '/api/category/search';

  //--------------------------------------------------
  // static 変数
  //--------------------------------------------------

  CategoryChange.lastSearchWord = '';
  CategoryChange.searchEnable = true;

  //--------------------------------------------------
  // static メソッド : メイン
  //--------------------------------------------------

  // ツリー
  CategoryChange.setup = function(categoryId) {
    var category = Category.get(categoryId);

    CategoryChange.clear();
    CategoryChange.appendButtonParent(categoryId);
    CategoryChange.appendButtonCurrent(categoryId, category.name, category.blog_count);

    var children = Category.getChildren(categoryId);

    $.each(children, function(index, category) {
      // 登録ブログが無いカテゴリは表示しない
      if (category.blog_count == 0) {
        return true;
      }

      var name = Category.getName(category.category_id, 1, false);

      if (category.sub_category_count > 0) {
        CategoryChange.appendButtonBranch(category.category_id, name, category.blog_count);
      } else {
        CategoryChange.appendButtonLeaf(category.category_id, name, category.blog_count);
      }
    });
  };

  // 検索
  CategoryChange.search = function(searchWord) {
    if (!CategoryChange.searchEnable || searchWord.length <= 0 || searchWord == CategoryChange.lastSearchWord) {
      return;
    }

    CategoryChange.lastSearchWord = searchWord;
    CategoryChange.searchEnable = false;

    $.get(CategoryChange.API_SEARCH, {
      search_word: searchWord
    }, function(data) {
      CategoryChange.searchEnable = true;

      var categories = [];
      $.each(data, function(index, category) {
        if (category.blog_count > 0) {
          categories.push(category);
        }
      });

      if (categories.length == 0) {
        CategoryChange.clear();
        CategoryChange.appendButtonBack();
        CategoryChange.appendListText('検索にヒットしませんでした');

      } else {
        CategoryChange.clear();
        CategoryChange.appendButtonBack();

        $.each(categories, function(index, category) {
          var name = Category.getName(category.category_id, 2, true);
          CategoryChange.appendButtonLeaf(category.category_id, name, category.blog_count);
        });
      }
    });
  };

  // カテゴリの決定
  CategoryChange.finish = function(categoryId) {
    CategoryChange.hide();
    window.location.href = contentBasePath + '/' + categoryId;
  };

  //--------------------------------------------------
  // static メソッド : UI : ダイアログ
  //--------------------------------------------------

  // 非表示
  CategoryChange.hide = function() {
    $(CategoryChange.REF_DIALOG).modal('hide');
  };

  //--------------------------------------------------
  // static メソッド : UI : リスト
  //--------------------------------------------------

  // クリア
  CategoryChange.clear = function() {
    $(CategoryChange.REF_LIST).empty();
  };

  // リストに jQuery オブジェクトを追加
  CategoryChange.append = function($object) {
    $(CategoryChange.REF_LIST).append($object);
  };

  // リストにテキストを追加
  CategoryChange.appendListText = function(text) {
    var $div = $('<div>')
      .addClass('list-group-item')
      .text(text);
    CategoryChange.append($div);
  };

  //--------------------------------------------------
  // static メソッド : UI : ボタン
  //--------------------------------------------------

  // 選択ボタンの追加 : 根
  CategoryChange.appendButtonBack = function() {
    var name = '<span>カテゴリ一覧に戻る</span>';
    $a = Category.generateListButton(Category.ROOT_CATEGORY_ID, name, -1, 0);
    CategoryChange.append($a);
  };

  // 選択ボタンの追加 : 親
  CategoryChange.appendButtonParent = function(categoryId) {
    var parentCategory = Category.getParent(categoryId);
    if (parentCategory == null) {
      return;
    }
    var name = '<span>親カテゴリへ</span>';
    $a = Category.generateListButton(parentCategory.category_id, name, -1, 0);
    CategoryChange.append($a);
  };

  // 選択ボタンの追加 : 現在
  CategoryChange.appendButtonCurrent = function(categoryId, name, badgeNumber) {
    name = '<span>' + name + '</span>';
    var $a = Category.generateListButton(categoryId, name, badgeNumber, 1);
    CategoryChange.append($a);
  };

  // 選択ボタンの追加 : 幹
  CategoryChange.appendButtonBranch = function(categoryId, name, badgeNumber) {
    name = Category.ICON_CHILD + '<span class="margin-left">' + name + '</span>' + Category.ICON_BRANCH;
    $a = Category.generateListButton(categoryId, name, badgeNumber, 0);
    CategoryChange.append($a);
  };

  // 選択ボタンの追加 : 葉
  CategoryChange.appendButtonLeaf = function(categoryId, name, badgeNumber) {
    name = Category.ICON_CHILD + '<span class="margin-left">' + name + '</span>';
    $a = Category.generateListButton(categoryId, name, badgeNumber, 1);
    CategoryChange.append($a);
  };

  //--------------------------------------------------
  // イベントハンドラ
  //--------------------------------------------------

  // ダイアログ表示時の処理
  CategoryChange.onShownBsModal = function() {
    CategoryChange.setup(Category.ROOT_CATEGORY_ID);
  };

  // 検索ワード入力時の処理
  CategoryChange.onKeyDown = function(e) {
    if (e.which && e.which === CategoryChange.KEY_CODE_ENTER ||
        e.keyCode && e.keyCode === CategoryChange.KEY_CODE_ENTER
    ) {
      var searchWord = $(CategoryChange.REF_SEARCH_WORD).val();
      CategoryChange.search(searchWord);
      return false;
    } else {
      return true;
    }
  };

  // 検索ボタンが押された時の処理
  CategoryChange.onClickSearchButton = function() {
    var searchWord = $(CategoryChange.REF_SEARCH_WORD).val();
    CategoryChange.search(searchWord);
  };

  // カテゴリボタンが押された時の処理
  CategoryChange.onClickCategoryButton = function() {
    var categoryId = $(this).data(Category.DATA_CATEGORY_ID);
    var isLink = $(this).data(Category.DATA_IS_LINK) == 1;

    if (isLink) {
      CategoryChange.finish(categoryId);
    } else {
      CategoryChange.setup(categoryId);
    }
  };

  window.CategoryChange = CategoryChange;

}(window));

